import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <a href="https://roamresearch.com/#/app/roamhacker">Roam-Hacker database</a>I
found out the Roam Hacker database. As you know, I am a huge huge fan or Roam
Research and this has been fascinating for me
    <a href="https://glitch.com/">
  Glitch: The friendly community where everyone builds the web
    </a>
My website, is a hand coded one. Glitch is basically a GitHub kind of tool. With
it, you can host a website. I use GitHub pages for mine [woopull : I will create
a product unboxing and demonstration video for $125 on fiverr.com
    <a href="https://www.roamstack.com">
  Lesson #4: Keeping your Roam data safe | RoamStack
    </a>
I have subscribed to Roam Stack and I really liked their Keeping your Roam Data Safe
    <a href="https://coveritup.com/products/official-harry-potter-gryffindor-hand-book">
  Official Harry Potter Hogwarts Hand Book Diary – Cover It Up
    </a>
Come on now isn&#39;t this the most nerdiest and prettiest thing ever?
    <a href="https://medium.com/@nickmilo22/why-progressive-summarization-must-die-c2635d1f79f1">
  Why Progressive Summarization Must Die | by Nick Milo | Aug, 2020 | Medium
    </a>
This article has been a nice one Here are my highlight but the summary is basically,
apply the Zettelkasten method and maybe use something like Roam Research. Progressive
Summarization is a specific process of reading, bolding, highlighting, summarizing,
and then finally, possibly, rewriting. Progressive Summarization encourages the same
thing as the industrial factory-based education system: the production of poor thinkers
who are skilled in the regurgitation of other people’s ideas Instead it could have
gone to developing your own nuanced perspective to the ideas you encounter, then
connecting them with other ideas you’ve already encountered. Let’s call this… Progressive
Ideation. Why care? The very essence and quality of our thinking is at stake. Why
relegate your best thinking to the last step in an elaborate process? If you are
a knowledge worker, an entrepreneur, or just someone who likes to think — Progressive
Summarization must die. Progressive Summarization Creates False Confidence The Collector’s
Fallacy — coined by Christian Tietze — argues that the act of “collecting” is easy.
It feels like progress but it’s not. The same is true with the act of “summarizing”.
Let’s call it… The Summarizer’s Fallacy. The last major reason why Progressive Summarization
must die is that it doesn’t effectively grow and evolve with you over time. In summary…
1. Progressive Summarization creates a false sense of achievement. 2. Progressive
Summarization encourages regurgitative thinking. 3. Progressive Summarization doesn’t
build a dynamic digital library. What is Progressive Ideation Progressive Summarization
is: “Copy, Bold, Highlight, Summarize, Rewrite.” Progressive Ideation is: “Comment,
Convert, Connect, Create.” For the sake of the quality of your thinking, the difference
is night and day. Make comments (and highlights) as you go. Convert them into new
notes. Progressive Ideation Creates Real Value When you read something new, what
if you added comments as you went? (Nothing major, just some comments like you were
having an engaging conversation with a friend.) What if you converted some of that
information into new notes, each representing a new idea, each written in your own
words? What if you then linked these new ideas to existing ideas in your note library?
Progressive Ideation Encourages Connective Thinking

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      